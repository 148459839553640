<template>
  <v-slide-y-transition mode="out-in">
    <v-snackbar
      height="50px"
      :top="isTop"
      :left="isLeft"
      :right="isRight"
      :bottom="isBottom"
      v-model="snackbarOpen"
      :color="color"
      :timeout="snackbarTimeout"
      v-if="snackbarOpen"
      @click.stop
    >
      <template v-if="icon">
        <v-row align="center">
          <v-col class="ma-0 py-0" cols="2">
            <v-row justify="center" align-content="center">
              <v-icon left color="white">{{ icon }}</v-icon>
            </v-row>
          </v-col>
          <v-col class="ma-0 py-0" cols="10" v-html="text"></v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col class="ma-0 py-0" cols="12" v-html="text"></v-col>
        </v-row>
      </template>
    </v-snackbar>
  </v-slide-y-transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Snackbar',
  computed: {
    snackbarOpen: {
      set(open) {
        return this.setOpen(open)
      },
      get() {
        return this.open
      }
    },
    snackbarTimeout: {
      set(miliseconds) {
        return this.setTimeout(miliseconds)
      },
      get() {
        return this.timeout
      }
    },
    ...mapGetters('snackbar', [
      'open',
      'text',
      'timeout',
      'x',
      'y',
      'color',
      'icon'
    ]),
    isTop() {
      return this.y === 'top'
    },
    isBottom() {
      return this.y === 'bottom'
    },
    isLeft() {
      return this.x === 'left'
    },
    isRight() {
      return this.x === 'right'
    }
  },
  methods: {
    ...mapActions('snackbar', [
      'setOpen',
      'setTimeout'
    ])
  }
}
</script>
